import swal from 'sweetalert';
import APIGetTargets from '../../../../api/APIGetTargets';
import APIUpdateUserTarget from '../../../../api/APIUpdateUserTarget';

const actionCellRenderer = (params: any) => {
    let eGui = document.createElement("div");   

    let editingCells = params.api.getEditingCells();
    // checks if the rowIndex matches in at least one of the editing cells
    let isCurrentRowEditing = editingCells.some((cell: any) => {
        return cell.rowIndex === params.node.rowIndex;
    });

    if (isCurrentRowEditing) {
        eGui.innerHTML = `
    <i class="fa fa-check" data-action="update" style="color:green"></i>
    <i class="fa fa-times" data-action="cancel"></i>
  `;
    } else {
        eGui.innerHTML = `
    <i class="fa fa-edit" data-action="edit" style="color:blue"></i>
  `;
    }

    return eGui;
}

const onCellClicked = (params: any) => {
    // Handle click event for action cells
    if (params.column.colId === "action" && params.event.target.dataset.action) {
        let action = params.event.target.dataset.action;

        if (action === "edit") {
            console.log(params.data)
            // params.api.startEditingCell({
            //     rowIndex: params.node.rowIndex,
            //     // gets the first columnKey
            //     colKey: params.columnApi.getDisplayedCenterColumns()[0].colId
            // });
            const { id } = params.data
            swal({
                text: 'Change user to available port. E.g: nmas',
                // @ts-ignore
                content: "input",
                button: {
                  text: "Change port!",
                  closeModal: false,
                },
              })
              .then(name => {
                if (!name) throw null;
                let token = localStorage.getItem('token');
                let tempResult, portId: Number;
                APIGetTargets(token)
                .then(result => {
                    console.log(result)
                    tempResult = result.map((e: any) => {
                        return {
                            id : e.key.id
                            , port : e.url
                        } 
                    })
                    
                    tempResult.map((obj: any) => {
                        if(name == obj.port) {
                            portId = obj.id
                        }
                    })  
                    if(portId > 0) {
                        APIUpdateUserTarget(id, portId)
                        .then(result => {
                             // @ts-ignore
                            swal.stopLoading();
                            // @ts-ignore
                            swal.close();
                            alert('Change port Ok')
                        })
                        .catch(error => console.log(error));
                    }else {
                        // @ts-ignore
                        swal.stopLoading();
                        // @ts-ignore
                        swal.close();
                        alert('wrong port')
                    } 

                })
              })
              .catch(err => {
                if (err) {
                  swal("Oh noes!", "The AJAX request failed!", "error");
                } else {
                }
              });
        }

        if (action === "delete") {
            
        }

        if (action === "update") {
            params.api.stopEditing(false);
        }

        if (action === "cancel") {
            params.api.stopEditing(true);
        }
    }
}

const onRowEditingStarted = (params: any) => {
    params.api.refreshCells({
        columns: ["action"],
        rowNodes: [params.node],
        force: true
    });
}

const onRowEditingStopped = (params: any) => {
    params.api.refreshCells({
        columns: ["action"],
        rowNodes: [params.node],
        force: true
    });
}

const onClickEdit: any = () => {
    function CellRenderer() { }
    CellRenderer.prototype.createGui = function () {
        var template =
            // should be a pencil icon 
            '<span><span id="theValue" style="padding-left: 4px;"></span><button style="float:right" id="theButton"><i class="fa fa-pencil" aria-hidden="true"></i></button></span>';
        var tempDiv = document.createElement("div");
        tempDiv.innerHTML = template;
        this.eGui = tempDiv.firstElementChild;
    };
    CellRenderer.prototype.init = function (params: any) {
        this.createGui();
        this.params = params;
        var eValue = this.eGui.querySelector("#theValue");
        eValue.innerHTML = params.value;
        this.eButton = this.eGui.querySelector("#theButton");
        this.buttonClickListener = this.onButtonClicked.bind(this);
        this.eButton.addEventListener("click", this.buttonClickListener);
    };
    CellRenderer.prototype.onButtonClicked = function () {
        var startEditingParams = {
            rowIndex: this.params.rowIndex,
            colKey: this.params.column.getId()
        };
        this.params.api.startEditingCell(startEditingParams);
    };
    CellRenderer.prototype.getGui = function () {
        return this.eGui;
    };
    CellRenderer.prototype.destroy = function () {
        this.eButton.removeEventListener("click", this.buttonClickListener);
    };
    return CellRenderer;
}

  

export {
    actionCellRenderer,
    onCellClicked,
    onRowEditingStarted,
    onRowEditingStopped,
    onClickEdit,
}

